import './polyfill';
(() => {
    if (window.location.search.includes('noraven=1')) return;
    const warn = console.warn;
    console.warn = (...args) => {
        if (args[0]?.message?.includes("Cannot read properties of undefined (reading 'appWrapperGetter')")) {
            return;
        }
        warn.call(console, ...args);
    };
})();
import('./shell/boostrap');
